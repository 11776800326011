import { render, staticRenderFns } from "./EditTender.vue?vue&type=template&id=2def830b&"
import script from "./EditTender.vue?vue&type=script&lang=ts&"
export * from "./EditTender.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports