




import Vue from 'vue'
import TenderForm from '@/views/Tender/views/TenderForm.vue'

export default Vue.extend({
  name: 'EditTender',
  components: { TenderForm },
  data: function (): {
    id: string
  } {
    const id = 'id' in this.$route.params && this.$route.params.id
    if (!id) {
      throw new Error('EditTender is missing required route param id')
    }
    return {
      id,
    }
  },
})
